import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import ContactForm from './ContactForm'; // Update path if necessary

const Contact = ({ needFullHeight }) => {
  return (
    <section className={`${needFullHeight && "min-h-100vh"} blog wrapper`} style={{ padding: '5vw 0' }}>
      <h2 className="section-title">Contact</h2>
      
      {/* Contact Form */}
      <div className="contact-form" style={{ marginBottom: '3rem' }}>
        <ContactForm />
      </div>
      
      {/* Social Links */}
      <div className="social-links" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <h3 className="section-title" style={{ marginTop: '5rem' }}>Socials</h3>
        <ul className="social-list" style={{ listStyle: 'none', padding: '0', display: 'flex', gap: '2rem' }}>
          <li>
            <a href="#" style={{ textDecoration: 'none', color: '#626262', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <FontAwesomeIcon icon={faTwitter} className="social-icon" style={{ fontSize: '2rem', marginBottom: '0.5rem' }} />
              Twitter
            </a>
          </li>
          <li>
            <a href="#" style={{ textDecoration: 'none', color: '#626262', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <FontAwesomeIcon icon={faFacebookF} className="social-icon" style={{ fontSize: '2rem', marginBottom: '0.5rem' }} />
              Facebook
            </a>
          </li>
          <li>
            <a href="#" style={{ textDecoration: 'none', color: '#626262', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <FontAwesomeIcon icon={faInstagram} className="social-icon" style={{ fontSize: '2rem', marginBottom: '0.5rem' }} />
              Instagram
            </a>
          </li>
          <li>
            <a href="#" style={{ textDecoration: 'none', color: '#626262', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <FontAwesomeIcon icon={faLinkedinIn} className="social-icon" style={{ fontSize: '2rem', marginBottom: '0.5rem' }} />
              LinkedIn
            </a>
          </li>
        </ul>
      </div>
      
      {/* Media Queries */}
      <style jsx>{`
        @media (max-width: 768px) {
          .social-list {
            flex-direction: column;
            align-items: center;
          }
        }

        @media (min-width: 769px) {
          .social-list {
            flex-direction: row;
          }
        }
      `}</style>
    </section>
  );
};

export default Contact;
